/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  postPlaylist({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/playlists`, data)
        .then(response => {
          // commit('SET_VIDEO', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlaylistById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/${id}`)
        .then(response => {
          commit('SET_PLAYLIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlaylists({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_PLAYLISTS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  putPlaylist({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/playlists`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removePlaylistById({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/playlists/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlaylistSelectList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/select-list`, { params: data })
        .then(response => {
          commit('SET_PLAYLISTS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlaylistType({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/options/playlist-type`, state.filters)
        .then(response => {
          commit('SET_TYPE_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getVideosOfPlaylist({ commit, state }) {
    return new Promise((resolve, reject) => {
      const { playlistBackupId, playlistBackupState, ...filteredPlaylist } = state.playlist
      axios
        .post(`/api/projects/${project.state.activeProjectId}/playlists/getVideos`, filteredPlaylist, {
          params: {
            currentItemId: state.playlist.currentItemId,
            userId: state.playlist.userId,
          },
        })
        .then(response => {
          commit('SET_PLAYLIST_VIDEOS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTrendingVideosOfPlaylist({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/playlists/videos-trending`, state.playlist)
        .then(response => {
          commit('SET_PLAYLIST_VIDEOS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getVideos({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/videos`, state.filtersOfVideo)
        .then(async response => {
          commit('SET_VIDEOS', response.data.result.items)
          commit('SET_VIDEOS_PAGINATION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getVideosByFilters({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/getVideos`, state.playlist)
        .then(async response => {
          commit('SET_VIDEOS_BY_FILTERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getOptions({ commit, state }, type) {
    return new Promise((resolve, reject) => {
      let url = `/api/projects/${project.state.activeProjectId}/playlists/select-options`
      if (type) {
        url += `?playlistTypeId=${type}`
      }
      axios
        .get(url)
        .then(async response => {
          commit('SET_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlaylistVideos({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/${id}/videos`)
        .then(response => {
          commit('SET_PLAYLIST_VIDEOS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  postPlaylistVideos({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/playlists/videos`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  putPlaylistVideos({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/playlists/videos`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  deletePlaylistVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/projects/${project.state.activeProjectId}/playlists/videos`, { data })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getEmbed({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/playlist/embed/${data.player}/${data.id}`)
        .then(async response => {
          // commit('SET_PAGINATION', response.data.result)
          // commit('SET_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getPlaylistDisplayTypes({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/playlists/options/playlist-display-types`)
        .then(response => {
          commit('SET_PLAYLIST_DISPLAY_TYPES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAspectRatio({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/aspect-ratios/select-list')
        .then(async response => {
          commit('SET_ASPECT_RATIOS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
