import CustomParameter from './CustomParameters'

class Playlist {
  constructor() {
    this.publicId = null
    this.title = ''
    this.description = ''
    this.maxVideos = 10
    this.playlistSortOptionId = 1
    this.includeTagsState = false
    this.includeTags = []
    this.includeTagMode = null
    this.includeTagModeId = 1
    this.excludeTagsState = false
    this.excludeTags = []
    this.excludeTagMode = null
    this.excludeTagModeId = 1
    this.includeFlagsState = false
    this.includeFlags = []
    this.excludeFlagsState = false
    this.excludeFlags = []
    this.folders = []
    this.aspectRatioTypeId = 0
    this.includeAllProjects = false
    this.includeProjects = []
    this.dateFrom = null
    this.dateTo = null
    this.playListTypeId = 0
    this.currentItemId = ''
    this.userId = ''
    this.playlistBackupId = ''
    this.playlistBackupState = false
    this.playlistType = {
      description: 'Select videos manually from your library',
      id: 1,
      name: 'Manual',
      value: 'MANUAL',
    }
    this.playlistDisplayTypeId = 1
    this.playlistDisplayType = {
      id: '1',
      name: 'Carousel',
      value: 'CAROUSEL',
    }
    this.canEmbed = false
    this.customParameters = []
    this.customParameters.push(new CustomParameter())
  }

  fromData(data) {
    const maxVideos = data.playlistType?.value === 'RECOMMENDATIONS' ? 25 : 10
    this.publicId = data.publicId || null
    this.title = data.title || ''
    this.description = data.description || ''
    this.maxVideos = data.maxVideos || maxVideos
    this.playlistSortOptionId = data.playlistSortOptionId || 1
    this.includeTags = data.includeTags || []
    this.excludeTags = data.excludeTags || []
    this.includeTagsState = data.includeTagsState || this.includeTags.length > 0
    this.excludeTagsState = data.excludeTagsState || this.excludeTags.length > 0
    this.includeTagMode = data.includeTagMode || null
    this.includeTagModeId = data.includeTagModeId || 1
    this.excludeTagMode = data.excludeTagMode || null
    this.excludeTagModeId = data.excludeTagModeId || 1
    this.includeFlags = data.includeFlags || []
    this.excludeFlags = data.excludeFlags || []
    this.includeFlagsState = data.includeFlagsState || this.includeFlags.length > 0
    this.excludeFlagsState = data.excludeFlagsState || this.excludeFlags.length > 0
    this.includeFlagModeId = data.includeFlagModeId || 1
    this.excludeFlagModeId = data.excludeFlagModeId || 1
    this.folders = data.folders || []
    this.aspectRatioTypeId = data.aspectRatioTypeId || 0
    this.includeAllProjects = data.includeAllProjects || false
    this.includeProjects = data.includeProjects || []
    this.dateFrom = data.dateFrom || null
    this.dateTo = data.dateTo || null
    this.playListTypeId = data.playListTypeId || 0
    this.currentItemId = data.currentItemId || ''
    this.userId = data.userId || ''
    this.playlistBackupId = data.playlistBackupId || ''
    this.playlistBackupState = data.playlistBackupState || false
    this.playlistType = Object.keys(data.playlistType).length > 0 ? data.playlistType : {
      description: 'Select videos manually from your library',
      id: 1,
      name: 'Manual',
      value: 'MANUAL',
    }
    this.playlistDisplayTypeId = data.playlistDisplayTypeId ? data.playlistDisplayTypeId : 1
    this.playlistDisplayType = data.playlistDisplayType ? data.playlistDisplayType : {
      id: '1',
      name: 'Carousel',
      value: 'CAROUSEL',
    }
    this.canEmbed = data.canEmbed || false
    this.customParameters = data.customParameters && data.customParameters.length > 0 ? data.customParameters.map(cp => {
      const ncp = new CustomParameter()
      ncp.fromData(cp)
      return ncp
    }) : [new CustomParameter()]
  }
}

export default Playlist
