<template>
  <div class="d-flex flex-column pb-sm-1">
    <h5
      class="text-main"
    >
      {{ title }}
    </h5>
    <span
      v-if="subTitle"
      class="d-none d-sm-flex text-sm text-main text-regular"
    >{{ subTitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
}

</script>
